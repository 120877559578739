import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import type { ModalQueueItem } from '@/types/components';
import { ga4Event } from '@/util/ga4Analytics';

/**
 * @note global state store adapted from https://academind.com/tutorials/global-state-management-with-react-hooks#is-context-api-the-solution-to-manage-it
 */
let MODAL_QUEUE: ModalQueueItem[] = [];
let listeners: Dispatch<SetStateAction<ModalQueueItem[]>>[] = [];

export const trackModalOpen = (name: string, triggeredFrom: string = 'unspecified'): undefined => {
  ga4Event(`modal:${name}`, {
    page: window.location.href,
    triggered_from: triggeredFrom,
  });
};

interface QueueModalReturnObject {
  trackModalOpen: typeof trackModalOpen;
}

/**
 * @note This depends on a <QueuedModals /> component to be present in the layout that will display the modals.
 */
export const useModalQueue = (): [
  ModalQueueItem[],
  (modalContent: ModalQueueItem) => QueueModalReturnObject,
  () => void,
] => {
  const [, setQueue] = useState(MODAL_QUEUE);

  const updateListeners = () => {
    for (let i = 0; i < listeners.length; i += 1) {
      listeners[i](MODAL_QUEUE);
    }
  };

  const queueModal = (modalContent: ModalQueueItem): QueueModalReturnObject => {
    MODAL_QUEUE = [...MODAL_QUEUE, modalContent];

    updateListeners();

    return {
      trackModalOpen,
    };
  };

  const unqueueModal = () => {
    const newQueue = [...MODAL_QUEUE];
    newQueue.splice(newQueue.length - 1, 1);
    MODAL_QUEUE = newQueue;

    updateListeners();
  };

  useEffect(() => {
    // when the component did mount, its corresponding setQueue function is added to the list, as a pointer
    listeners.push(setQueue);

    // de-register the component from the listeners
    return () => {
      listeners = listeners.filter((li) => li !== setQueue);
    };
  }, [setQueue]);

  return [MODAL_QUEUE, queueModal, unqueueModal];
};
