import { Locale, Segment } from '@fixter/i18n';

export const getSegmentFromLocale = (locale: Locale): Segment => {
  switch (locale) {
    case Locale.FR_FR:
      return Segment.Fixter_FR;
    case Locale.EN_GB:
      return Segment.Fixter_UK;
    default:
      return Segment.Fixter_UK;
  }
};
